
<template>
    <svg :width="size + 'px'" :fill="color" viewBox="0 0 101.2 100.677">
        <path id="Garantia_icon" data-name="Garantia icon" d="M9357.9,1552.56l-6.813-7.236-9.165,3.861-.175.066a2.951,2.951,0,0,1-3.753-1.818l-3.259-9.39-9.94-.2h-.191a2.954,2.954,0,0,1-2.691-3.186l.871-9.909-9-4.223-.17-.087a2.946,2.946,0,0,1-1.162-4l4.782-8.7-6.521-7.517-.1-.13a2.948,2.948,0,0,1,.55-4.13l7.9-6-2.881-9.51-.047-.172a2.945,2.945,0,0,1,2.172-3.552l9.686-2.26,1.232-9.867a1.356,1.356,0,0,0,.031-.191l0-.014a2.939,2.939,0,0,1,3.445-2.324l9.766,1.873,5.155-8.525a1.531,1.531,0,0,1,.106-.163,2.948,2.948,0,0,1,4.1-.732l8.155,5.682,8.161-5.682.161-.106a2.951,2.951,0,0,1,4.045,1l5.142,8.516,9.765-1.873.194-.031a2.952,2.952,0,0,1,3.287,2.56l1.232,9.867,9.683,2.26.175.047a2.95,2.95,0,0,1,1.958,3.679l-2.884,9.51,7.921,6a3.071,3.071,0,0,1,.563,4.26l-6.522,7.517,4.782,8.7a1.8,1.8,0,0,1,.087.165,2.951,2.951,0,0,1-1.412,3.913l-9,4.223.869,9.909v.2a2.944,2.944,0,0,1-2.886,3l-9.94.2-3.262,9.39c-.016.061-.042.118-.063.18a2.948,2.948,0,0,1-3.863,1.573l-9.156-3.861-6.811,7.236-.138.139a2.951,2.951,0,0,1-4.163-.139Zm2.078-20.591a28.192,28.192,0,1,0-28.193-28.2,28.191,28.191,0,0,0,28.193,28.2Zm-.025-5.887a22.3,22.3,0,1,1,22.3-22.306v.009a22.3,22.3,0,0,1-22.3,22.3Zm-11.386-24.459a2.608,2.608,0,0,0-.319,3.677l6.712,6.861a2.611,2.611,0,0,0,3.687,0l13.022-13.088a2.586,2.586,0,1,0-3.668-3.646l-11.166,11.22-4.874-4.978-.057-.061a2.61,2.61,0,0,0-3.337.016Z" transform="translate(-9309.305 -1453.313)" fill="#a5b2b7" stroke="rgba(0,0,0,0)" stroke-width="1"/>
    </svg>
</template>

<script>
export default {
    name: 'iconWarranty',
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        }
    }
}
</script>

<style scoped>
</style>

